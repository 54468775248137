<template>
  <div class="box">
    <!-- 头部 -->
    <headers></headers>

    <el-carousel :interval="3000">
      <el-carousel-item>
        <img src="@/assets/homePage/联系我们banner.png" class="images" />
      </el-carousel-item>
    </el-carousel>
    <!-- 内容区域 -->
    <div class="content">
      <div class="content_1">
        <h2 class="H2">企业联系方式</h2>
        <p>重庆公司</p>
        <p style="text-indent: 2em">盛泰光电科技股份有限公司</p>
        <p>公司地址</p>
        <p style="text-indent: 2em">重庆市大足区通桥街道西湖大道13号</p>
        <br />
        <p>江西公司</p>
        <p style="text-indent: 2em">江西盛泰精密光学有限公司</p>
        <p>公司地址</p>
        <p style="text-indent: 2em">江西省新余市分宜县城东盛泰工业园</p>
        <br />
        <p>深圳公司</p>
        <p style="text-indent: 2em">深圳盛泰光电科技有限公司</p>
        <p>公司地址</p>
        <p style="text-indent: 2em">深圳市龙华区华繁路嘉安达大厦 2505号</p>
        <br />
        <p>集团公司接入电话: 023-85226001</p>
        <p>深圳公司接入电话: 0755-23088055</p>
        <p>集团公司接入传真: 023-85226001</p>
      </div>
      <!-- <div class="content_2">
        <h2 class="H2">商业合作伙伴</h2>
        <div class="logo">
          <a :href="item.hrefS" target="_blank" v-for="item in cooperation" :key="item.id">
            <img :src="item.src" />
          </a>

        </div>
      </div> -->
      <div class="content_3">
        <div class="home">
          <h2 class="H3">全球分支机构</h2>
        </div>
      </div>
      <div class="images">
        <div class="p_01">
          <p v-for="item in siteList" :key="item.id">{{ item.title }}</p>
          <!-- <p class="p_01_1" style="height: 20px;" >台湾盛泰光电有限公司办事处</p> -->
        </div>
        <img src="@/assets/contact/地图.png" alt="" />
        <!-- <img src="@/assets/网站用图/线条.png" class="image_1">
        <img src="@/assets/网站用图/分布图.png" class="image_2"> -->
      </div>
    </div>
    <!-- 底部 -->
    <foot></foot>
  </div>
  <div class="_div">
    <move_header></move_header>
    <!-- 轮播图 -->
    <van-swipe :autoplay="3000" indicator-color="white">
      <van-swipe-item>
        <img src="@/assets/homePage/联系我们banner.png" style="width: 100%" />
      </van-swipe-item>
    </van-swipe>
    <!-- 内容区域 -->
    <h2>企业联系方式</h2>
    <div class="info1">
      <p>重庆公司</p>
      <p style="text-indent: 2em">盛泰光电科技股份有限公司</p>
      <p>公司地址</p>
      <p style="text-indent: 2em">重庆市大足区通桥街道西湖大道13号</p>
      <br />
      <p>江西公司</p>
      <p style="text-indent: 2em">江西盛泰精密光学有限公司</p>
      <p>公司地址</p>
      <p style="text-indent: 2em">江西省新余市分宜县城东盛泰工业园</p>
      <br />
      <p>深圳公司</p>
      <p style="text-indent: 2em">深圳盛泰光电科技有限公司</p>
      <p>公司地址</p>
      <p style="text-indent: 2em">深圳市龙华区华繁路嘉安达大厦 2505号</p>
      <br />
      <p>集团公司接入电话: 023-85226001</p>
      <p>集团公司接入传真: 023-85226001</p>
    </div>
    <h2>全球分支机构</h2>
    <div class="info1">
      <p v-for="item in siteList" :key="item.id">{{ item.title }}</p>
    </div>
    <!-- 底部 -->
    <move_foot></move_foot>
  </div>
</template>

<script>
import foot from "../components/foot.vue";
import headers from "../components/header.vue";
import move_foot from "../move_components/move_foot.vue";
import move_header from "../move_components/move_header.vue";
import { reactive, toRefs } from "@vue/reactivity";

export default {
  components: { foot, headers, move_foot, move_header },
  setup() {
    const state = reactive({
      siteList: [
        { id: 1, title: "盛泰光电科技股份有限公司" },
        { id: 2, title: "江西盛泰精密光学有限公司" },
        { id: 3, title: "深圳盛泰光电科技有限公司" },
        { id: 4, title: "新加坡办公室" },
        { id: 5, title: "印度工厂" },
        { id: 6, title: "盛泰光电东南亚办事处" },
        { id: 7, title: "盛泰光电美国加州办事处" },
      ],
      dataList: [
        { id: 1, title: "企业业务联系方式" },
        { id: 2, title: "商业合作伙伴信息" },
        { id: 3, title: "国内分支机构" },
      ],
    });
    return {
      ...toRefs(state),
    };
  },
};
</script>

<style scoped lang="less">
p {
  font-weight: 400;
}

.p_01_1 {
  margin-top: 25px;
}

.logo {
  // display: flex;
  flex-wrap: wrap;

  img:nth-child(10) {
    padding-bottom: 25px;
  }

  img:nth-child(11) {
    padding-bottom: 30px;
  }

  img:nth-child(15) {
    padding-bottom: 3px;
  }

  img {
    margin: 0 auto;
    flex: 1;
    cursor: pointer;
    max-width: 20%;
    min-width: 20%;
    padding: 15px 63px;
    box-sizing: border-box;
  }
}

.images {
  // width: 100%;
  display: flex;
  position: relative;
  img {
    width: 70%;
    position: absolute;
    right: 0;
    top: -31.5rem;
  }
  .image_1 {
    width: 100%;
    height: 200px;
    margin-top: 28px;
    margin-left: 30px;
  }

  .image_2 {
    height: 100%;
    width: 100%;
    max-width: 1200px;
    margin-top: -46px;
  }

  .p_01 {
    width: 30%;
    min-width: 208px;
    margin-left: 2rem;
    p {
      margin: 0;
      padding: 0;
      margin: 20px 0;
      // width: 240px;
      height: auto;
      overflow: hidden;
    }
  }
}

.content_3 {
  position: relative;
  height: 70px;

  .home,
  .foreign {
    position: absolute;
  }

  .foreign {
    left: 270px;
  }
}

.H2 {
  width: 100%;
  position: relative;
  font-size: 1.7rem;
}

.H2::before {
  content: "";
  width: 30%;
  height: 2px;
  background-color: #c7000b;
  position: absolute;
  bottom: -3px;
  left: 7%;
  transform: translateX(-50%);
  // top:55px;
  min-width: 35px;
  max-width: 35px;
}

.H3 {
  font-size: 1.7rem;
}

.H3::before {
  content: "";
  width: 30%;
  height: 2px;
  background-color: #c7000b;
  position: absolute;
  bottom: 0.9rem;
  left: 47%;
  transform: translateX(-50%);
  // top:55px;
  min-width: 35px;
  max-width: 35px;
}

.el-carousel {
  max-width: 1920px;
  margin: 0 auto;
}

.box {
  width: 100%;

  .content {
    width: 70%;
    margin: 50px auto;
    max-width: 1920px;

    .content_1 {
      position: relative;
      // display: flex;
      flex-wrap: wrap;
      p {
        margin-left: 2rem;
      }
      .phone {
        flex: 1;
        max-width: 20%;
        min-width: 20%;
      }

      .work {
        max-width: 70%;
        min-width: 70%;
        flex: 4;
      }

      .fax {
        flex: 1;
        max-width: 20%;
        min-width: 20%;
      }

      .plant {
        max-width: 70%;
        min-width: 70%;
        flex: 4;
      }
    }

    .content_2 {
      height: auto;
      position: relative;
    }
  }
}

::v-deep .el-carousel {
  max-width: 1920px;
  margin: 0 auto;
  overflow-y: hidden;
  height: auto;
  max-height: 600px;
  // max-height: 700px;
}

::v-deep .el-carousel__container {
  height: 100% !important;
  min-height: 500px;
}

.el-carousel {
  max-width: 1920px;
  margin: 0 auto;
}

::v-deep .el-card {
  border-radius: 8px;
}

.images {
  width: 100%;
  height: auto;
  max-height: 600px;
}

// 小屏幕区域
@media only screen and (min-width: 767px) {
  .box {
    display: block;
  }

  ._div {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .box {
    display: none;
  }

  ._div {
    display: block;
    h2 {
      text-align: center;
      font-size: 80px;
    }
    h2::before {
      content: "";
      width: 30%;
      height: 8px;
      background-color: #c7000b;
      position: absolute;
      margin-top: 100px;
      left: 50%;
      transform: translateX(-50%);
      min-width: 100px;
      max-width: 100px;
    }
    .info1 {
      font-size: 40px;
      padding: 0px 70px;
      box-sizing: border-box;
    }
  }
}
</style>
